import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: '', // e.g: 'Name | Developer'
  lang: '', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: 'Danny Roberts',
  subtitle: 'Front-End Developer',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: '',
  paragraphTwo: '',
  paragraphThree: '',
  paragraphFour: '',
  resume: 'https://www.resumemaker.online/es.php', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'homeView.jpg',
    title: "Let's Stay Home Drinks Co",
    info:
      'This is a website built for a small cocktails delivery service based in Surrey. This webpage utilises Javascript, React, Framer Motion and Styled Components.',
    info2: '',
    url: 'https://lets-stay.netlify.app/',
    repo: 'https://github.com/dannyr22/lets-stay-home', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'musicPlayer.jpg',
    title: 'Chill Sounds Music Player',
    info:
      'Music player web app. This app fetches the music using an api request from https://chillhop.com and is then added to the library. The user is able to search through the playlist selecting whichever song they want. The player has all of the standard controls. This web app is made with Javascript, React and Sass.',
    info2: '',
    url: 'https://music-grooving.netlify.app/',
    repo: 'https://github.com/dannyr22/music-player-react', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'element.jpg',
    title: 'Element Game Search',
    info:
      'Game search web app. It shows upcoming, popular and new games and it allows the user to also search for whichever game they like. The app retrieves the data by making an api request to https://rawg.io/. This app is made using Javascript, React, Redux, Framer Motion and Styled Components.',
    info2: '',
    url: 'https://gaming-gaming-gaming.netlify.app/',
    repo: 'https://github.com/dannyr22/element-react-redux-app', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'snapshot.jpg',
    title: 'Snapshot Filming Company',
    info:
      'Snapshot is webpage for a fictional filming company. This webpage ustilises Javascript, React, Framer Motion and Styled Components',
    info2: '',
    url: 'https://snapshot-filming-guru.netlify.app/',
    repo: 'https://github.com/dannyr22/snapshot', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'movie.jpg',
    title: 'Movie World Database',
    info:
      'This is a movie database web app. The app fetches the data by making an api request to https://api.themoviedb.org. The user is then able to search for any movie they wish. This app utilises Javascript, React, and CSS.',
    info2: '',
    url: 'https://movie-world-database.netlify.app/',
    repo: 'https://github.com/dannyr22/movie-search-react-app', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'dannyroberts.b@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    // {
    //   id: nanoid(),
    //   name: 'twitter',
    //   url: 'https://twitter.com/',
    // },
    // {
    //   id: nanoid(),
    //   name: 'codepen',
    //   url: '',
    // },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/daniel-b-roberts-138821134/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/dannyr22',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: true, // set to false to disable the GitHub stars/fork buttons
};
